<template>
  <div class="profileOverview">
    <div class="profileOverview__left">
      <base-panel class="profileOverview__panelPersonalDetails">
        <profile-overview-personal-details />
      </base-panel>

      <base-panel class="profileOverview__panelNotifications">
        <profile-overview-notifications />
      </base-panel>
    </div>

    <div class="profileOverview__right">
      <base-panel class="profileOverview__panelMembership">
        <profile-overview-membership />
      </base-panel>
    </div>
  </div>
</template>

<script>
import BasePanel from '@/components/BasePanel';
import ProfileOverviewPersonalDetails from '@/components/profile/ProfileOverviewPersonalDetails';
import ProfileOverviewMembership from '@/components/profile/ProfileOverviewMembership';
import ProfileOverviewNotifications from '@/components/profile/ProfileOverviewNotifications';

export default {
  components: {
    BasePanel,
    ProfileOverviewPersonalDetails,
    ProfileOverviewNotifications,
    ProfileOverviewMembership,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileOverview {
  @include desktop {
    display: flex;
    justify-content: space-between;
  }
}

.profileOverview__left,
.profileOverview__right {
  @include desktop {
    flex: 0 0 calc(50% - #{rem(8px)});
    display: flex;
    flex-direction: column;
  }
}

.profileOverview__panelPersonalDetails {
  margin: 0 0 rem(16px) 0;
}

.profileOverview__panelMembership {
  margin: rem(16px) 0 0 0;
  height: 100%;

  @include desktop {
    margin: 0;
  }
}

.profileOverview__panelNotifications {
  @include desktop {
    flex: 1 0 auto;
  }
}
</style>